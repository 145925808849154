import { Preferences } from '@capacitor/preferences';
import { useEffect, useState } from 'react';

export function usePreference(
    key: string,
    defaultValue: string,
): { value: string | undefined; loading: boolean } {
    const [value, setValue] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        void Preferences.get({ key: key }).then((value) => {
            setValue(value.value ?? defaultValue);
            setLoading(false);
        });
    }, []);

    return { value, loading };
}
