import { ReactComponent as AnalyticsIcon } from '@public/icons/analytics.svg';
import { ReactComponent as BellIcon } from '@public/icons/bell.svg';
import { ReactComponent as Caret } from '@public/icons/caret-left.svg';
import { ReactComponent as Heart } from '@public/icons/heart.svg';
import { ReactComponent as Info } from '@public/icons/sheet-info.svg';
import { InterestPanel } from '@components/interest/interest-panel';
import { LoadingSpinner } from '@components/loading-spinner';
import {
    NotificationService,
    NotificationType,
} from '../services/notification';
import {
    PREFERENCES_ANALYTICS_CONSENT_KEY,
    PREFERENCES_NOTIFICATIONS_CONSENT_KEY,
    StorageServices,
} from '../services/storage';
import { ParsedInterest } from '@api/validations';
import { Preferences } from '@capacitor/preferences';
import { ViewsType } from '@typesSrc/views';
import { useDirectus } from '../hooks/useDirectus';
import { useTranslation } from 'react-i18next';
import AnimateHeight from 'react-animate-height';
import BaseView from '@views/base-view';
import React, { ChangeEvent, SVGProps, useEffect, useState } from 'react';
import Toggle from 'react-toggle';

type IconType = React.ComponentType<
    SVGProps<SVGSVGElement> & { title?: string }
>;
const toggles: {
    titleKey: string;
    Icon: IconType;
    preferenceKey: string;
    onChange?: (value: boolean) => Promise<void>;
}[] = [
    {
        titleKey: 'notification',
        Icon: BellIcon,
        preferenceKey: PREFERENCES_NOTIFICATIONS_CONSENT_KEY,
        onChange: async (value) => {
            if (!value) {
                await NotificationService.clearAllPendingNotifications();
            }
        },
    },
    {
        titleKey: 'analysis',
        Icon: AnalyticsIcon,
        preferenceKey: PREFERENCES_ANALYTICS_CONSENT_KEY,
    },
];

function SettingsToggle({
    title,
    Icon,
    titleKey,
    preferenceKey,
    onChange,
}: {
    title: string;
    Icon: IconType;
    titleKey: string;
    preferenceKey: string;
    onChange?: (value: boolean) => Promise<void>;
}) {
    const [checked, setChecked] = React.useState(false);
    useEffect(() => {
        void Preferences.get({ key: preferenceKey }).then((result) => {
            setChecked(result.value === 'true');
        });
    }, []);

    return (
        <button className="flex flex-row justify-between items-center py-5 border-t border-border space-x-4 w-full">
            <Icon className="h-6 w-6 fill-rosenholz" />
            <div className="text-base font-bold text-greyDark text-left flex-1">
                {title}
            </div>

            <Toggle
                className="mx-4"
                checked={checked}
                icons={false}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setChecked(event.target.checked);
                    void Preferences.set({
                        key: preferenceKey,
                        value: event.target.checked.toString(),
                    });
                    void onChange?.(event.target.checked);
                }}
                data-testid={`setting_${titleKey}`}
                data-cy={`setting_${titleKey}`}
            />
        </button>
    );
}

function SettingsView() {
    const { t } = useTranslation('settings');
    const [checked, setChecked] = useState<ParsedInterest[]>([]);
    const [showInterest, setShowInterest] = useState<boolean>(false);
    const { data, loading } = useDirectus((directusClient) =>
        directusClient.getInterests(),
    );

    useEffect(() => {
        StorageServices.getInterests()
            .then((interests) => {
                if (interests) {
                    setChecked(interests);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    const handleChange = (
        selectedInterest: ParsedInterest,
        selected: boolean,
    ) => {
        let newChecked = [...checked];
        if (selected) {
            if (
                !newChecked.find(
                    (interest) => interest.id === selectedInterest.id,
                )
            ) {
                newChecked = [...checked, selectedInterest];
            }
        } else {
            newChecked = newChecked.filter(
                (interest) => interest.id !== selectedInterest.id,
            );
            void NotificationService.clearPendingNotificationsForNotificationType(
                NotificationType.EVENT,
            );
        }
        void StorageServices.setInterests(newChecked);
        setChecked(newChecked);
    };
    if (loading) {
        return (
            <BaseView
                view={ViewsType.SETTINGS}
                title="Einstellungen"
                showBack={true}
                showSettings={false}
            >
                <div className="w-screen h-[55vh] flex justify-center items-center">
                    <LoadingSpinner />
                </div>
            </BaseView>
        );
    }
    return (
        <BaseView
            view={ViewsType.SETTINGS}
            title="Einstellungen"
            showBack={true}
            showSettings={false}
        >
            <div className="px-4">
                {toggles.map(({ titleKey, Icon, preferenceKey, onChange }) => (
                    <SettingsToggle
                        key={preferenceKey}
                        Icon={Icon}
                        title={t(titleKey)}
                        titleKey={titleKey}
                        preferenceKey={preferenceKey}
                        onChange={onChange}
                    />
                ))}
                <div>
                    {['privacy', 'imprint'].map((setting) => (
                        <a
                            key={`settings_${setting}`}
                            className="flex flex-row justify-between py-5 border-t border-border gap-4"
                            target="_blank"
                            href={t(`${setting}.url`)}
                            rel="noreferrer"
                        >
                            <Info className="h-6 w-6 fill-rosenholz" />
                            <div className="text-base font-bold text-greyDark text-left flex-1">
                                {t(`${setting}.label`)}
                            </div>
                            <Caret className="rotate-180 h-6 w-6 stroke-schiefer" />
                        </a>
                    ))}
                </div>

                {data ? (
                    <AnimateHeight
                        duration={500}
                        height={showInterest ? 'auto' : 64}
                        data-cy="settings-interest-list"
                        data-testid="settings-interest-list"
                    >
                        <div className={`border-y border-border py-5`}>
                            <button
                                className="w-full flex justify-between mb-5"
                                onClick={() => setShowInterest(!showInterest)}
                                data-cy="settings-interest-toggle"
                                data-testid="settings-interest-toggle"
                            >
                                <div className="flex gap-4">
                                    <Heart className="h-6 w-6 fill-rosenholz" />
                                    <p className="text-base font-bold text-greyDark text-left">
                                        {t('preferences')}
                                    </p>
                                </div>
                                <Caret
                                    className={`${
                                        showInterest
                                            ? '-rotate-90'
                                            : 'rotate-180'
                                    } h-6 w-6 stroke-schiefer`}
                                />
                            </button>
                            <InterestPanel
                                data={data}
                                handleChange={handleChange}
                                checked={checked}
                            />
                        </div>
                    </AnimateHeight>
                ) : (
                    <div className={`border-t border-border py-5 opacity-50`}>
                        <div className="w-full flex justify-between mb-5">
                            <div className="flex gap-4">
                                <Heart className="h-6 w-6 fill-rosenholz" />
                                <p className="text-base font-bold text-greyDark text-left">
                                    {t('preferences-error')}
                                </p>
                            </div>
                            <Caret
                                className={`${
                                    showInterest ? '-rotate-90' : 'rotate-180'
                                } h-6 w-6 stroke-schiefer`}
                            />
                        </div>
                    </div>
                )}
            </div>
        </BaseView>
    );
}

export default SettingsView;
