import './i18n';
import './styles/globals.scss';
import 'react-toggle/style.css';
import { AppUrlOpenEvent } from '@typesSrc/events';
import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';
import { Environment } from './environment.global';
import { LocalNotifications } from '@capacitor/local-notifications';
import { NotificationService } from './services/notification';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import { SafeSpaceContextWrapper } from './context/safe-space-context';
import { closeInAppBrowser } from '@utils/in-app-browser';
import AppInitializer from '@utils/app-initialization';
import DonationAppealDetail from '@components/donations/donation-appeal-detail';
import DonationSuccess from '@components/donations/donation-success';
import DonationView from '@views/donation';
import ErrorView from '@views/error';
import EventView from '@views/event';
import EventsView from '@views/events';
import IntercessionOrder from '@components/intercession/intercession-order';
import IntercessionStartscreen from '@components/intercession/startscreen-intercession';
import IntercessionView from '@views/intercession';
import InterestsView from '@views/interests';
import NewsOverviewView from '@views/news-overview';
import NewsView from '@views/news';
import PaymentCanceled from '@components/intercession/payment-canceled';
import React from 'react';
import ReactDOM from 'react-dom/client';
import SettingsView from './views/settings';
import Success from '@components/success';
import reportWebVitals from './report-web-vitals';

const router = createHashRouter([
    {
        path: '/',
        element: <InterestsView />,
        errorElement: <ErrorView />,
    },
    {
        path: 'termine',
        element: <EventsView />,
        children: [
            {
                path: ':eventId',
                element: <EventView />,
            },
        ],
    },
    {
        path: 'neuigkeiten',
        element: <NewsOverviewView />,
        children: [
            {
                path: ':newsId',
                element: <NewsView />,
            },
        ],
    },
    {
        path: 'foerdern',
        element: <DonationView />,
        children: [
            {
                path: ':donationId',
                element: <DonationAppealDetail />,
            },
            {
                path: 'erfolg',
                element: <DonationSuccess />,
            },
        ],
    },
    {
        path: 'gebet',
        element: <IntercessionView />,
        children: [
            {
                index: true,
                element: <IntercessionStartscreen />,
            },
            {
                path: 'erfolg',
                element: <Success />,
            },
            {
                path: 'abbruch',
                element: <PaymentCanceled />,
            },
        ],
    },
    {
        path: 'gebet/bestellung',
        element: <IntercessionOrder />,
    },
    {
        path: 'termin/:eventId',
        element: <EventView />,
    },
    {
        path: 'neuigkeit/:newsId',
        element: <NewsView />,
    },
    {
        path: 'einstellungen',
        element: <SettingsView />,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

void LocalNotifications.addListener(
    'localNotificationActionPerformed',
    (payload) => {
        const extra = payload.notification.extra as { route: string };
        const route = extra.route;
        router.navigate(route);
    },
);

// Note that this might be called multiple for a single link. The implementation must be robust enough.
const onDeepLinkOpened = (url?: string) => {
    // Deep links typically originate from the in-app browser. Make sure to close it.
    closeInAppBrowser();

    // Navigate to the linked URL.
    // Do not navigate, if the URL does not start with the public URL
    // Example urls:
    //   https://app.berlinerdom.de/#/foerdern/erfolg
    //      slug = /foerdern/erfolg
    //   https://app.berlinerdom.de/admin#/foerdern/7
    //      slug = /foerdern/7
    const match = (url ?? '').match(
        new RegExp(`^${Environment.PUBLIC_URL}.*?#(/.*)`),
    );
    if (match) {
        router.navigate(match[1]);
    }
};

void CapacitorApp.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
    // A deep link was opened (https://developer.apple.com/ios/universal-links/, https://developer.android.com/training/app-links/verify-android-applinks)
    onDeepLinkOpened(event.url);
});

window.addEventListener('appUrlOpen', (event) => {
    // We triggered a deep link internally. This can happen if the in-app browser encountered a link that should be opened in the app.
    onDeepLinkOpened((event as AppUrlOpenEvent).url);
});

void NotificationService.requestNotification();

// For testing  purposes, I created an AppInitializer utility-file.
// For now, it only enables the back-button-behaviour.
// Other initialization-task can be moved here too, to enable easier testing of those.
void AppInitializer.init();

root.render(
    <React.StrictMode>
        <SafeSpaceContextWrapper>
            <RouterProvider router={router} />
        </SafeSpaceContextWrapper>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
