import { Helmet, HelmetProvider } from 'react-helmet-async';
import { NOTIFICATION_SET_KEY } from '@components/events/event-overview';
import { NotificationService, NotificationType } from './services/notification';
import {
    PREFERENCES_NOTIFICATIONS_CONSENT_KEY,
    StorageServices,
} from './services/storage';
import { directusClient } from '@api/directus-client';
import { useLocation } from 'react-router-dom';
import { usePreference } from './hooks/usePreference';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

/* istanbul ignore next */
export const matomo = (trackingData: any) =>
    (window as unknown as { _paq?: any[] })._paq?.push(trackingData);

function testingWithJest() {
    return (
        process.env.JEST_WORKER_ID !== undefined &&
        process.env.NEXT_PHASE === undefined
    );
}

// Determine default consent - used for proper testing
function getDefaultConsent(
    consentOverride?: boolean | null,
): undefined | boolean {
    const useOverride = consentOverride !== undefined;
    let defaultConsent: boolean | undefined = false;
    if (testingWithJest()) {
        defaultConsent = false;
    }
    if (useOverride) {
        defaultConsent = consentOverride ?? undefined;
    }
    return defaultConsent;
}

export function HTMLHead({
    title = 'Berliner Dom',
    consentOverride,
}: {
    title?: string;
    consentOverride?: boolean | null; //Only used for testing!
}) {
    const defaultConsent = getDefaultConsent(consentOverride);
    const [consent, setConsent] = React.useState<boolean | undefined>(
        defaultConsent,
    );

    const { t } = useTranslation('analyticsConsent');

    const { value: notificationConsent, loading: loadingNotificationConsent } =
        usePreference(PREFERENCES_NOTIFICATIONS_CONSENT_KEY, 'false');

    useEffect(() => {
        if (
            window.sessionStorage.getItem(NOTIFICATION_SET_KEY) ||
            loadingNotificationConsent ||
            notificationConsent !== 'true'
        ) {
            return;
        }
        void directusClient.getEventNotifications().then(async (events) => {
            if (!events) {
                return;
            }
            await NotificationService.clearPendingNotificationsForNotificationType(
                NotificationType.EVENT,
            );
            const notifications = events
                .map((event) =>
                    event.notifications.map((notification) => ({
                        title: notification.title,
                        body: notification.description ?? '',
                        id: notification.id,
                        //TODO: Add image, once supported: https://github.com/ionic-team/capacitor-plugins/issues/430
                        //attachments: [
                        //    {
                        //        id: `${notification.id}`,
                        //        url: '',
                        //    },
                        //],
                        extra: {
                            route: `/termine/${event.id}`,
                            type: NotificationType.EVENT,
                        },
                        schedule: {
                            at: notification.date.toDate(),
                            allowWhileIdle: true,
                        },
                    })),
                )
                .flat();
            await NotificationService.scheduleNotifications(notifications);
            window.sessionStorage.setItem(NOTIFICATION_SET_KEY, 'true');
        });
    }, [notificationConsent, loadingNotificationConsent]);

    React.useEffect(() => {
        if (testingWithJest()) {
            return;
        }
        /* istanbul ignore next */
        void StorageServices.getAnalyticsConsent().then((res) => {
            setConsent(res);
        });
        /* istanbul ignore next */
        return () => {
            // Remove the script added below and the script added by this script in order to prevent duplicate scripts
            // and to remove the scripts if the consent is revoked.
            const matomoScript = document.querySelector(`script[id="matomo"]`);
            if (matomoScript) {
                matomoScript.remove();
            }
            const matomoInternalScript = document.querySelector(
                `script[id="matomo-internal"]`,
            );
            if (matomoInternalScript) {
                matomoInternalScript.remove();
            }
        };
    }, []);

    const location = useLocation();

    useEffect(() => {
        if (!consent || testingWithJest()) {
            return;
        }
        /* istanbul ignore next */
        matomo(['setCustomUrl', '/' + window.location.hash.substring(1)]);
        /* istanbul ignore next */
        matomo(['setDocumentTitle', title]);
        /* istanbul ignore next */
        matomo(['trackPageView']);
        /* istanbul ignore next */
        void StorageServices.getInterests().then((interests) => {
            matomo([
                'setCustomVariable',
                1,
                'Interessen',
                interests?.map((i) => i.name).join(','),
                'visit',
            ]);
        });
    }, [location, consent, title]);

    if (consent === undefined) {
        return (
            <div
                className="fixed top-0 flex justify-center items-center w-screen h-screen bg-black/60 z-20"
                data-testid="consentScreen"
            >
                <div className="fixed mx-3 max-w-lg h-max bg-white rounded-2xl flex flex-col p-4">
                    <h2 className="text-xl font-bold pb-4">{t('title')}</h2>
                    <p className="text-sm pb-2">{t('text1')} </p>
                    <p className="text-sm pb-2 mb-3">{t('text2')}</p>
                    <button
                        className="mb-4 p-2 bg-rosenholz rounded-lg w-full text-center text-white"
                        onClick={() => {
                            void StorageServices.setAnalyticsConsent(true);
                            setConsent(true);
                        }}
                        data-cy="acceptButton"
                        data-testid="acceptButton"
                    >
                        {t('accept')}
                    </button>
                    <button
                        className="p-2 bg-white border border-rosenholz rounded-lg w-full text-center text-rosenholz"
                        onClick={() => {
                            void StorageServices.setAnalyticsConsent(false);
                            setConsent(false);
                        }}
                        data-cy="declineButton"
                        data-testid="declineButton"
                    >
                        {t('Ablehnen')}
                    </button>
                </div>
            </div>
        );
    }

    /* istanbul ignore next */
    return (
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
                {consent && !testingWithJest() && (
                    <script id="matomo" type="text/javascript">
                        {`
                var _paq = _paq || [];
                _paq.push([
                    'setCustomVariable',
                    1,
                    "Environment",
                    "${process.env.NODE_ENV}",
                    "visit"
                ]);
                _paq.push(['enableHeartBeatTimer']);
                _paq.push(['enableLinkTracking']);
                (function() {
                    var u="https://www.berlinerdom.de/analytics.berlinerdom.de/";
                    _paq.push(['setTrackerUrl', u+'piwik.php']);
                    _paq.push(['setSiteId', '2']);
                    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                    g.type='text/javascript'; g.id="matomo-internal"; g.async=true; g.defer=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);
                })();
            `}
                    </script>
                )}
            </Helmet>
        </HelmetProvider>
    );
}
