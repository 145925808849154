import { DIRECTUS_DATE_PATTERN } from '@utils/constants';
import { EventHighlight } from '@components/events/event-highlight';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from '@components/loading-spinner';
import { NoData } from '@components/no-data-error';
import { ParsedEvent, ParsedHighlight } from '@api/validations';
import { shouldHidePriceTags } from '@utils/eventUtils';
import { usePaginatedDirectus } from '../../hooks/usePaginatedDirectus';
import { useTranslation } from 'react-i18next';
import Card from '@components/card/card';
import DefaultHeaderPicture from '@public/default_header_picture.jpg';
import InfiniteScroll from 'react-infinite-scroller';
import React, { useMemo } from 'react';
import dayjs, { Dayjs } from '@utils/dayjs';
import groupBy from 'lodash.groupby';

export function isEventLive(event: ParsedEvent): boolean {
    const now = dayjs();
    return (
        event.is_streamed &&
        event.imported_event.start_date.isSame(now, 'day') &&
        now.isAfter(event.imported_event.start_date.subtract(10, 'minutes')) &&
        now.isBefore(event.imported_event.end_date)
    );
}

export const NOTIFICATION_SET_KEY = 'notifications_set';

function EventOverview() {
    const { data, loading, fetchMoreData, hasMore, error } =
        usePaginatedDirectus(
            (directusClient, offset, pageSize) =>
                directusClient.getAllEvents(offset, pageSize),
            50,
        );

    const mapEventToEventCard = (event: ParsedEvent) => {
        const importedEvent = event.imported_event;
        const live = isEventLive(event);
        const hidePriceTags = shouldHidePriceTags(event.imported_event.title);
        const free: boolean | null = hidePriceTags
            ? null
            : !importedEvent.shop_url;

        return (
            <div key={event.id}>
                {event.highlights.map((highlight, index) => (
                    <EventHighlight
                        key={index}
                        highlight={highlight as ParsedHighlight}
                    />
                ))}
                <Link to={`${event.id}`}>
                    <Card
                        free={free}
                        hidePriceTags={hidePriceTags}
                        price={event.price ?? undefined}
                        teaserImageUrl={
                            importedEvent.image
                                ? (
                                      JSON.parse(importedEvent.image) as {
                                          url: string;
                                          description: string;
                                      }
                                  ).url
                                : DefaultHeaderPicture
                        }
                        category={importedEvent.category ?? ''}
                        title={importedEvent.title ?? ''}
                        startDate={importedEvent.start_date}
                        endDate={importedEvent.end_date}
                        live={live}
                    />
                </Link>
            </div>
        );
    };

    const generateEventListForDate = (date: Dayjs, events: ParsedEvent[]) => {
        const isThisYear = dayjs()
            .startOf('year')
            .isSame(date.startOf('year'), 'year');
        return (
            <div className="max-w-3xl mx-auto" key={date.toISOString()}>
                <div className="relative flex pb-6 pt-2 items-center text-schiefer opacity-80 justify-center">
                    <span className="flex-shrink mx-4">
                        <div>
                            {date.isSame(dayjs(), 'day') ? t('today') : ''}
                            {date.format(
                                isThisYear ? 'dddd DD.MM.' : 'dddd DD.MM.YYYY',
                            )}
                        </div>
                    </span>
                </div>
                <div className="grid sm:grid-cols-2 sm:gap-y-2.5 sm:gap-x-4">
                    {events.map(mapEventToEventCard)}
                </div>
            </div>
        );
    };

    const { t } = useTranslation('event');
    const groupData = useMemo(() => {
        if (!data) {
            return null;
        }
        const endOfYesterday = dayjs().subtract(1, 'day').endOf('day');
        const validEventsOnly = data
            .filter((data) => {
                const importedEvent = data.imported_event;
                return (
                    importedEvent.end_date.isAfter(endOfYesterday) &&
                    data.status === 'published'
                );
            })
            .sort((a, b) => {
                const aPinned = a.highlights.some((h) => h.pinned);
                const bPinned = b.highlights.some((h) => h.pinned);
                if (aPinned && !bPinned) {
                    return -1;
                }
                if (!aPinned && bPinned) {
                    return 1;
                }
                return a.imported_event.start_date > b.imported_event.end_date
                    ? 1
                    : -1;
            });
        return Object.entries(
            groupBy<ParsedEvent>(validEventsOnly, (event: ParsedEvent) =>
                event.imported_event.start_date.format(DIRECTUS_DATE_PATTERN),
            ),
        ).map(([date, events]) => {
            return generateEventListForDate(
                dayjs(date, DIRECTUS_DATE_PATTERN),
                events,
            );
        });
    }, [data]);

    if (!groupData) {
        if (hasMore) {
            return (
                <div className="w-screen h-[55vh] flex justify-center items-center">
                    <LoadingSpinner />
                </div>
            );
        }
        return <NoData />;
    }

    const generateDateList = () => {
        if (loading || !hasMore) {
            return;
        }
        void fetchMoreData();
    };
    return (
        <InfiniteScroll
            loadMore={generateDateList}
            hasMore={hasMore}
            initialLoad={true}
            useWindow={false}
            className="flex justify-center"
        >
            <div className="mx-4 w-full">
                {groupData.slice(
                    0,
                    hasMore ? groupData.length - 1 : groupData.length,
                )}
                {loading && (
                    <div className="w-full h-[35vh] flex justify-center items-center">
                        <LoadingSpinner />
                    </div>
                )}
                {error}
            </div>
        </InfiniteScroll>
    );
}

export default EventOverview;
