import {
    LocalNotificationSchema,
    PermissionStatus,
} from '@capacitor/local-notifications/dist/esm/definitions';
import { LocalNotifications } from '@capacitor/local-notifications';
import { NOTIFICATION_SET_KEY } from '@components/events/event-overview';
import { StorageServices } from './storage';

export enum NotificationType {
    EVENT = 'event',
    DONATION_REMINDER = 'donationReminder',
}

export class NotificationService {
    static async requestNotification(): Promise<void> {
        const permission = await LocalNotifications.checkPermissions();
        let permissionAfterRequest: PermissionStatus = {
            display: permission.display,
        };
        if (
            permission.display !== 'granted' &&
            permission.display !== 'denied'
        ) {
            permissionAfterRequest =
                await LocalNotifications.requestPermissions();
        }
        if (
            (await StorageServices.getNotificationConsent()) === null &&
            permissionAfterRequest.display === 'granted'
        ) {
            await StorageServices.setNotificationConsent(true);
        }
    }

    static async scheduleNotifications(
        notifications: LocalNotificationSchema[],
    ): Promise<void> {
        if (await StorageServices.getNotificationConsent()) {
            await NotificationService.requestNotification();

            // LocalNotifications.schedule throws an error on iOS when passed notifications in the past. Make sure we only pass dates in the future.
            const now = new Date();
            const futureNotifications = notifications.filter(
                (n) => (n.schedule?.at ?? now) > now,
            );
            await LocalNotifications.schedule({
                notifications: futureNotifications,
            });
        }
    }

    static async clearAllPendingNotifications(): Promise<void> {
        const pending = await LocalNotifications.getPending();
        if (pending.notifications.length > 0) {
            await LocalNotifications.cancel({
                notifications: pending.notifications,
            });
        }
        window.sessionStorage.removeItem(NOTIFICATION_SET_KEY);
    }

    static async clearPendingNotificationsForNotificationType(
        notificationType: NotificationType,
    ): Promise<void> {
        const pending = await LocalNotifications.getPending();
        const relevantNotifications = pending.notifications.filter(
            (notification) =>
                (notification.extra as { type: NotificationType | undefined })
                    .type === notificationType,
        );
        if (relevantNotifications.length > 0) {
            await LocalNotifications.cancel({
                notifications: relevantNotifications,
            });
        }
        window.sessionStorage.removeItem(NOTIFICATION_SET_KEY);
    }
}
